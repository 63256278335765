import dynamic from 'next/dynamic';
import { GetStaticProps } from 'next';
import slugify from 'slugify';

import client from '@utils/apolloClient';
import { getHomepageEntryPoints } from '@queries/homepage.graphql';
import { getAllTags } from '@queries/tags.graphql';

import getEntryBoxInfo from '@utils/getEntryBoxInfo';
import getImagePath from '@utils/getImagePath';
import sortBy from '@utils/sortBy';

import Layout from '@components/Layout/Layout';
import Tags, { TagList } from '@components/Tags/Tags';

const Cover = dynamic(() => import('@components/Cover/Cover'));
const ContentList = dynamic(
  () => import('@components/ContentList/ContentList')
);
const SecondaryTitle = dynamic(
  () => import('@components/SecondaryTitle/SecondaryTitle')
);

type IndexProps = {
  collections: any;
  description: string;
  features: any;
  featureFlags: any;
  highlights: any;
  homepage: any;
  news: any;
  reviews: any;
  tags: TagList;
  title: string;
};

const Index = ({
  collections,
  features,
  featureFlags,
  highlights,
  homepage,
  news,
  reviews,
  tags,
  title,
}: IndexProps) => {
  return (
    <Layout
      featureFlags={featureFlags}
      pageDescription="TheCollector is the home of the latest LEGO news; set reviews and features on the biggest and best LEGO sets."
      pageTitle="LEGO News, Reviews, Features and Deals"
      pageType="website"
      section="Home"
      siteTitle={title}
      url="/"
    >
      <SecondaryTitle>Highlights</SecondaryTitle>
      <ContentList limit={true} posts={highlights || []} />

      <SecondaryTitle url="/news">News</SecondaryTitle>
      <ContentList
        moreLink={{ text: 'More News', url: '/news' }}
        limit={true}
        posts={news || []}
      />
      <SecondaryTitle url="/features">Features</SecondaryTitle>
      <ContentList
        moreLink={{ text: 'More Features', url: '/features' }}
        limit={true}
        posts={features || []}
      />
      {featureFlags.collections ? (
        <>
          <SecondaryTitle>Collections</SecondaryTitle>
          <ContentList
            moreLink={{ text: 'More Collections', url: '/collections' }}
            limit={true}
            posts={collections || []}
          />
        </>
      ) : null}
      <SecondaryTitle url="/reviews">Reviews</SecondaryTitle>
      <ContentList
        moreLink={{ text: 'More Reviews', url: '/reviews' }}
        limit={true}
        posts={reviews || []}
      />

      <SecondaryTitle>Popular Tags</SecondaryTitle>
      <Tags tags={tags} />
    </Layout>
  );
};

export default Index;

export const getStaticProps: GetStaticProps = async () => {
  const configData = await import(`../siteconfig.json`);

  const {
    data: {
      CollectionItems: { items: collections },
      FeatureItems: { items: features },
      HomepageItem: homepage,
      NewsItems: { items: news },
      ReviewItems: { items: reviews },
    },
  } = await client.query({
    query: getHomepageEntryPoints,
    variables: {
      length: 8,
      page: 1,
    },
  });

  // extract 4 highlights

  const highlights = sortBy(
    'date',
    [
      ...(configData.featureFlags.collections
        ? collections.map((item) => ({ ...item, section: 'Collections' }))
        : []),
      ...features.map((item) => ({ ...item, section: 'Features' })),
      ...news.map((item) => ({ ...item, section: 'News' })),
      ...reviews.map((item) => ({ ...item, section: 'Reviews' })),
    ].filter((post) => post.content.highlight),
    true
  ).slice(0, 4);

  const coverImage = homepage.content?.image?.url;

  const blurCoverImage = getImagePath({
    dimensions: '6x6',
    quality: 75,
    url: coverImage,
  });
  const fullCoverImage = getImagePath({
    url: coverImage,
  });

  const filterPosts = (arr, removeArr) => {
    return arr
      .filter((post) => !removeArr.find((rmPost) => rmPost.name === post.name))
      .slice(0, 4);
  };

  const {
    data: {
      Tags: { items: tags },
    },
  } = await client.query({
    query: getAllTags,
  });

  const tagCloud = sortBy(
    'name',
    sortBy(
      'count',
      tags.map((tag) => ({ ...tag, slug: slugify(tag.name, { lower: true }) })),
      true
    ).slice(0, 20),
    false
  );

  return {
    props: {
      description: configData.default.description,
      collections: configData.featureFlags.collections
        ? collections.map((post) => getEntryBoxInfo(post, 'Collections'))
        : null,
      featureFlags: configData.featureFlags,
      features: filterPosts(features, highlights).map((post) =>
        getEntryBoxInfo(post, 'Features')
      ),
      homepage: {
        ...homepage,
        content: {
          ...homepage.content,
          image: {
            blur: blurCoverImage,
            full: fullCoverImage,
          },
        },
      },
      highlights: highlights.map((post) => getEntryBoxInfo(post)),
      news: filterPosts(news, highlights).map((post) =>
        getEntryBoxInfo(post, 'News')
      ),
      reviews: filterPosts(reviews, highlights).map((post) =>
        getEntryBoxInfo(post, 'Reviews')
      ),
      tags: tagCloud,
      title: configData.default.title,
    },
  };
};
