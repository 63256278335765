import { memo } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 2rem;
  max-width: ${(props) => props.theme.maxContentWidth};
  padding: 0 1rem;
`;

const Tag = styled.a`
  background: var(--theme-color-grey2);
  color: var(--theme-color-text);
  display: block;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  text-transform: uppercase;

  :last-child {
    margin-right: 0;
  }

  &:hover {
    background: var(--theme-color-section-home);
    color: var(--theme-color-background);
  }
`;

type Tag = {
  count?: number;
  name: string;
  slug: Lowercase<string>;
};

export type TagList = Array<Tag>;

type TagsProps = {
  tags: TagList;
};

const Tags = ({ tags }: TagsProps) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {[...tags].map((tag) => {
        const slug = tag.slug.toLowerCase();
        return (
          <Link href={`/tags/${slug}`} key={slug} passHref legacyBehavior>
            <Tag>{tag.name}</Tag>
          </Link>
        );
      })}
    </Wrapper>
  );
};

export default memo(Tags);
